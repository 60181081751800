import React from "react";

import "./SideDrawer.css";

import Logo from "../../assets/image/AtlasLogoTransparentSide2.png";
import Location from "../../assets/Icons/map.svg";
import Phone from "../../assets/Icons/phone.svg";
import Time from "../../assets/Icons/time.svg";
import Message from "../../assets/Icons/message.svg";
import X from "../../assets/Icons/X.png";

import { Link, NavLink, withRouter } from "react-router-dom";

const sideDrawer = (props) => {
	let drawerClasses = "side-drawer";

	if (props.show) {
		drawerClasses = "side-drawer open";
	}

	const isActive = (pathname, state = {}) => {
		const currentLocation = props.location;
		return (
			currentLocation.pathname === pathname &&
			(!state.lang ||
				(currentLocation.state && currentLocation.state.lang === state.lang))
		);
	};

	return (
		<nav className={drawerClasses}>
			<ul className="SideDrawerList">
				<div className="SideDrawerTop">
					<Link to="/" onClick={props.click}>
						<img className="sideDrawerLogo" src={Logo} alt="Atlas" />
					</Link>
					<div onClick={props.click}>
						<img className="sideDrawerCloser" src={X} alt="X" />
					</div>
				</div>

				<div className="SideDrawerCenter">
					<li className="SideDrawerCategory">
						<NavLink
							exact
							to={{
								pathname: "/sjukrathjalfun",
								state: { lang: "icelandic" },
							}}
							onClick={props.click}
							className={`sjukrathjalfunUnderLink ${
								isActive("/sjukrathjalfun", { lang: "icelandic" })
									? "activeTab"
									: ""
							}`}
						>
							Sjúkraþjálfun
						</NavLink>
					</li>
					<li className="SideDrawerCategory">
						<NavLink
							exact
							to="/sjukrathjalfun/gjaldskra"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Gjaldskrá
						</NavLink>
					</li>

					<li className="SideDrawerCategory">
						<NavLink
							to="/starfsfolk"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Starfsfólk
						</NavLink>
					</li>

					<li className="SideDrawerCategory">
						<NavLink
							exact
							to="/umokkur"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Um okkur
						</NavLink>
					</li>
					<li className="SideDrawerSubCategory">
						<NavLink
							exact
							to="/umokkur/engjavegur"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Engjavegur
						</NavLink>
					</li>

					<li className="SideDrawerSubCategory">
						<NavLink
							exact
							to="/umokkur/slettuvegur"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Sléttuvegur
						</NavLink>
					</li>

					<li className="SideDrawerCategory">
						<NavLink
							to="/frettir"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Fréttir og greinar
						</NavLink>
					</li>

					<li className="SideDrawerCategory">
						<NavLink
							to="/hafasamband"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Hafa samband
						</NavLink>
					</li>

					<li className="SideDrawerCategory">
						<NavLink
							exact
							to="/umokkur/personuvernd"
							onClick={props.click}
							activeClassName="activeTab"
						>
							Persónuvernd
						</NavLink>
					</li>
					<li className="SideDrawerCategory">
						<NavLink
							exact
							to={{
								pathname: "/sjukrathjalfun",
								state: { lang: "english" },
							}}
							onClick={props.click}
							className={`sjukrathjalfunUnderLink ${
								isActive("/sjukrathjalfun", { lang: "english" })
									? "activeTab"
									: ""
							}`}
						>
							Physiotherapy - English
						</NavLink>
					</li>
					<li className="SideDrawerCategory">
						<NavLink
							exact
							to={{
								pathname: "/sjukrathjalfun",
								state: { lang: "spanish" },
							}}
							onClick={props.click}
							className={`sjukrathjalfunUnderLink ${
								isActive("/sjukrathjalfun", { lang: "spanish" })
									? "activeTab"
									: ""
							}`}
						>
							Fisioterapia - Español
						</NavLink>
					</li>
				</div>

				<div className="SideDrawerInfo">
					<ul className="SideDrawerInfoList">
						<li>
							<a href="/hafasamband" onClick={props.click}>
								<img className="sideDrawerIconImg" src={Phone} alt="Sími" />
								<p> 5526600 </p>
							</a>
						</li>

						<li>
							<a href="/umokkur/engjavegur" onClick={props.click}>
								<img
									className="sideDrawerIconImg"
									src={Location}
									alt="Staðsetning"
								/>
								<p> Engjavegi 6, 104 Reykjavík </p>
							</a>
						</li>

						<li>
							<a href="/umokkur/slettuvegur" onClick={props.click}>
								<img
									className="sideDrawerIconImg"
									src={Location}
									alt="Staðsetning"
								/>
								<p> Sléttuvegi 25, 103 Reykjavík </p>
							</a>
						</li>

						<li>
							<a href="/hafasamband" onClick={props.click}>
								<img className="sideDrawerIconImg" src={Message} alt="Email" />
								<p> afgreidsla@atlasendurhaefing.is </p>
							</a>
						</li>

						<li>
							<a href="/hafasamband" onClick={props.click}>
								<img
									className="sideDrawerIconImg"
									src={Time}
									alt="Opnunartími"
								/>
								<p> mán-fim 8:00-17:00 / fös 8:00-16:00 </p>
							</a>
						</li>
					</ul>
				</div>
			</ul>
		</nav>
	);
};

export default withRouter(sideDrawer);
