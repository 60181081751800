import React from "react";
import Location from "../../assets/Icons/map.svg";
import Phone from "../../assets/Icons/phone.svg";
import Time from "../../assets/Icons/time.svg";
import Message from "../../assets/Icons/message.svg";

import "./Footer.css";

const footer = (props) => (
	<footer className="footer">
		<nav className="footer_navigation">
			<div className="footer_navigation-items">
				<ul>
					<li>
						<a href="/umokkur/engjavegur">
							<img className="footerIcon" src={Location} alt="Location logo" />
							<p> Engjavegi 6, 104 Rvk </p>
						</a>
					</li>
					<li>
						<a href="/umokkur/slettuvegur">
							<img className="footerIcon" src={Location} alt="Location logo" />
							<p> Sléttuvegur 25, 103 Rvk </p>
						</a>
					</li>
					<li>
						<a href="/hafasamband">
							<img className="footerIcon" src={Phone} alt="Phone logo" />
							<p> Móttaka: 5526600 </p>
						</a>
					</li>
					<li className="emailFooter">
						<a href="/hafasamband">
							<img className="footerIcon" src={Message} alt="Contact logo" />
							<p> afgreidsla@atlasendurhaefing.is </p>
						</a>
					</li>
					<li>
						<a href="/hafasamband">
							<img className="footerIcon" src={Time} alt="Time logo" />
							<p> mán-fim 8:00-17:00 / fös 8:00-16:00 </p>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	</footer>
);

export default footer;
