import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Gjaldskra() {
	const [selectedOption, setSelectedOption] = useState("gjaldskra");

	const openInNewTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	return (
		<div className="gjaldskraBody">
			<div className="TitleWButton">
				<h1>Gjaldskrá</h1>
				<div className="titleButtons">
					<div className="btn-div-titles">
						<button
							onClick={() => setSelectedOption("gjaldskra")}
							className={`btn btn-border-pop ${
								selectedOption === "gjaldskra" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Gjaldskrá</p>
						</button>
						<button
							onClick={() => setSelectedOption("greidslur")}
							className={`btn btn-border-pop ${
								selectedOption === "greidslur" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Greiðslukerfi SÍ</p>
						</button>
					</div>
				</div>
			</div>

			<div className="GjaldskraFlex">
				{selectedOption === "gjaldskra" ? (
					<LazyLoadImage
						src="https://res.cloudinary.com/atlasendurhaefing/image/upload/v1719775831/atlas2.0/gjaldskra/db3bs7uv5qq6w5hiuont.jpg"
						alt="Gjaldskrá"
						className="GjaldskraSize"
						effect="blur"
					/>
				) : (
					<LazyLoadImage
						src="https://res.cloudinary.com/atlasendurhaefing/image/upload/v1717543015/atlas2.0/gjaldskra/jbf4xduy7u3wypaocmxc.jpg"
						alt="Greiðslukerfi SÍ"
						className="GjaldskraSize"
						effect="blur"
					/>
				)}

				<div className="GjaldskraTextSection">
					<p>
						Sjálfstætt starfandi sjúkraþjálfarar hafa samþykkt samninginn við
						Sjúkratryggingar Íslands og munu komugjöld því falla niður frá og
						með 1. júní 2024.
					</p>
					<p>
						Frá 1. febrúar 2023 er hægt að koma til sjúkraþjálfara í 6 skipti án
						beiðni. Ef þarf að koma oftar en 6 skipti þarf að liggja fyrir
						beiðni frá lækni til að fá niðurgreitt frá Sjúkratryggingum Íslands.
						Börn 2ja-17 ára greiða ekkert gjald fyrir þjálfun gegn framvísun
						beiðni, annars greiða þau 30% af gjaldi sjúkratryggðra.
					</p>
					<p>
						Börn 2ja-17 ára greiða ekkert gjald fyrir þjálfun gegn framvísun
						beiðni, annars greiða þau 30% af gjaldi sjúkratryggðra.
					</p>
					<h3>
						Sjá nánar:{" "}
						<p
							className="Linkur"
							onClick={() =>
								openInNewTab(
									"https://www.sjukra.is/um-okkur/fjarhaedir-og-gjaldskrar"
								)
							}
						>
							www.sjukra.is
						</p>
					</h3>
					<p>
						Afboða þarf bókaðan tíma hjá sjúkraþjálfara með góðum fyrirvara ef
						fólk hefur ekki tök á að mæta. Ef afboð berst innan 2 klukkustunda
						fyrir bókaðan tíma þarf að greiða 7.000 króna gjald. Vinsamlegast
						athugið að tímabókanir eru á ábyrgð viðskiptavina og
						Sjúkratryggingar Íslands taka ekki þátt í tímum sem ekki er mætt í.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Gjaldskra;
