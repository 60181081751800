import React from "react";
import Map from "../Map/MapSlettuvegur";
import { Link } from "react-router-dom";

function Slettuvegur() {
	return (
		<div className="Body">
			<div className="Title">
				<h1> Atlas Endurhæfing - Sléttuvegur 25, 103 Reykjavík </h1>
			</div>
			<div className="stadSections">
				<div className="stadRightSection">
					<p>
						Atlas Endurhæfing er með útibú að Sléttuvegi 25 í húsi Sléttunnar,
						Hrafnistu. Gengið er inn aðal inngang Sléttunnar og er Atlas
						Endurhæfing þar á vinstri hönd þegar komið er inn.
					</p>

					<h4>Athugið</h4>
					<p>Endurhæfing eftir liðskiptaaðgerðir hjá Klinik fara fram hér</p>

					<h4> Opnunartími </h4>
					<p>
						Mánudaga - fimmtudaga 8:00 - 17:00
						<br />
						Föstudaga 8:00 - 16:00 <br />
					</p>
					<h4> Heimilisfang </h4>
					<p> Sléttuvegur 25, 103 Reykjavík</p>

					<h4> Sjá Atlas Engjavegi </h4>
					<div className="btn-div-stads">
						<Link to="/umokkur/engjavegur" className="btn btn-border-pop">
							<p className="btnLabel">Engjavegur 6, 104 Reykjavík</p>
						</Link>
					</div>
				</div>

				<div className="stadLeftSection">
					<div className="map">
						<Map />
					</div>
				</div>
			</div>
			<div className="BottomSection">
				<img
					src={
						"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692105453/atlas2.0/locations/slettuvegur_v1jqf9.jpg"
					}
					alt="Sléttuvegur"
					className="stadImgSize"
				/>
			</div>
		</div>
	);
}

export default Slettuvegur;
