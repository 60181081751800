import React from "react";
import NewsSlider from "../NewsSlider/NewsSlider";

import Location from "../../assets/Icons/map.svg";
import Message from "../../assets/Icons/message.svg";
import Group from "../../assets/Icons/group.svg";
import Time from "../../assets/Icons/time.svg";

import { Link } from "react-router-dom";
import FrontPageSlider from "../ImageSlider/FrontPageSlider";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function FrontPage() {
	const images = [
		"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692290191/atlas2.0/banners/AtlasBanner03_kbhzqq.jpg",
		"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692290191/atlas2.0/banners/AtlasBanner02_n7ehrb.jpg",
		"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692291808/atlas2.0/banners/AtlasBanner01_i7dk9u.jpg",
	];

	const randomImage = images[Math.floor(Math.random() * images.length)];

	return (
		<div className="pagebody">
			<div className="bannerImageSpace">
				<LazyLoadImage
					src={randomImage}
					alt="banner"
					className="bannerImgSize"
					effect="blur"
				/>

				<div className="overlayText">Velkomin í Atlas Endurhæfingu</div>
			</div>
			<div className="infoSpace">
				<nav className="info_navigation">
					<div className="info_navigation-items">
						<ul>
							<Link to="/umokkur/engjavegur" className="infoFlex">
								<div className="infoTextFlex">
									<div className="infoTextTitle">
										<img className="infoIcon" src={Time} alt="Opnunartími" />
										<h3> Opnunartími </h3>
									</div>
									<p className="infoTextDescription">
										Mánudaga - fimmtudaga: 8:00 - 17:00
										<br /> Föstudaga: 8:00 - 16:00
									</p>
								</div>
							</Link>
							<Link to="/umokkur/engjavegur" className="infoFlex">
								<div className="infoTextFlex">
									<div className="infoTextTitle">
										<img
											className="infoIcon"
											src={Location}
											alt="Staðsetning"
										/>
										<h3> Staðsetning </h3>
									</div>
									<p className="infoTextDescription">
										Engjavegur 6, 104 Reykjavík <br />
										Sléttuvegur 25, 103 Reykjavík <br />
									</p>
								</div>
							</Link>
							<Link to="/hafasamband" className="infoFlex">
								<div className="infoTextFlex">
									<div className="infoTextTitle">
										<img className="infoIcon" src={Message} alt="Email" />
										<h3>Hafðu samband </h3>
									</div>
									<p className="infoTextDescription">
										Afboð þurfa að berast að lágmarki tveim tímum fyrir bókaðan
										tíma
									</p>
								</div>
							</Link>
							<Link to="/starfsfolk" className="infoFlex">
								<div className="infoTextFlex">
									<div className="infoTextTitle">
										<img className="infoIcon" src={Group} alt="Starfsfólk" />
										<h3> Starfsfólk </h3>
									</div>
									<p className="infoTextDescription">
										Sjúkraþjálfarar Atlas hafa mismunandi áherslur og áhugasvið
									</p>
								</div>
							</Link>
						</ul>
					</div>
				</nav>
			</div>

			<div className="frontInfoSpace">
				<div className="frontInfo">
					<div className="frontInfoLeft">
						<FrontPageSlider />
					</div>
					<div className="frontInfoRight">
						<div>
							<h2 className="frontInfoText">
								Atlas Endurhæfing er á tveimur stöðum
							</h2>
							<div className="btn-div">
								<Link to="/umokkur/engjavegur" className="btn btn-border-pop">
									<p className="btnLabel">
										Engjavegi 6, 104 Reykjavík í Íþróttamiðstöðinni Laugardal
									</p>
								</Link>
							</div>

							<div className="btn-div">
								<Link to="/umokkur/slettuvegur" className="btn btn-border-pop">
									<p className="btnLabel">
										Sléttuvegi 25, 103 Reykjavík í húsi Sléttunar, Hrafnistu
									</p>
								</Link>
							</div>
							<div className="frontInfoDescriptionAddition">
								<h4> Athugið: </h4>
								<p>
									Endurhæfing eftir liðskiptaaðgerðir fer fram að Sléttuvegi.
								</p>
							</div>
						</div>
						<h2 className="frontInfoText">Hafðu samband</h2>

						<div className="frontInfoHafasambandContainer">
							<Link to="/hafasamband" className="frontInfoHafasamband">
								<h4> Netfang: </h4>
								<p className="frontInfoDescriptionAdditionHS">
									afgreidsla@atlasendurhaefing.is
								</p>
							</Link>
							<Link to="/hafasamband" className="frontInfoHafasamband">
								<h4> Sími: </h4>
								<p className="frontInfoDescriptionAdditionHS">5526600</p>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="updatesSpace">
				<Link to="/frettir" className="frettirFrontPageLink">
					<h2> Fréttir og greinar </h2>
				</Link>
				<div className="news">
					<NewsSlider />
				</div>
			</div>
		</div>
	);
}

export default FrontPage;
