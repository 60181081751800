import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class FrontPageSlider extends React.Component {
	render() {
		const settings = {
			infinite: true,
			autoplay: true,
			arrows: false,
			autoplaySpeed: 3000,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		const slideImages = [
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692272441/atlas2.0/images/front1_yi4qdg.jpg",
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692272441/atlas2.0/images/front2_zhj4kz.jpg",
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692272441/atlas2.0/images/front3_orinmx.jpg",
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692272441/atlas2.0/images/front4_aamump.jpg",
		];
		return (
			<div className="allSliderImages">
				<Slider {...settings} className="StadSlider">
					<div>
						<LazyLoadImage
							src={slideImages[0]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>

					<div>
						<LazyLoadImage
							src={slideImages[1]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>

					<div>
						<LazyLoadImage
							src={slideImages[2]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>

					<div>
						<LazyLoadImage
							src={slideImages[3]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>
				</Slider>
			</div>
		);
	}
}

export default FrontPageSlider;
