import React, { Component } from "react";
import "./App.css";

import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import Footer from "./components/Footer/Footer";
import FrontPage from "./components/Pages/FrontPage";
import Sjukrathjalfun from "./components/Pages/Sjukrathjalfun";
import Gjaldskra from "./components/Pages/Gjaldskra";
import UmOkkur from "./components/Pages/UmOkkur";
import Starfsfolk from "./components/Pages/Starfsfolk";
import Einstaklingur from "./components/Pages/Einstaklingur";
import Engjavegur from "./components/Pages/Engjavegur";
import Slettuvegur from "./components/Pages/Slettuvegur";
import Frettir from "./components/Pages/Frettir";
import Personuvernd from "./components/Pages/Personuvernd";
import HafaSamband from "./components/Pages/HafaSamband";
import News from "./components/Pages/News";
import Page404 from "./components/Pages/Page404.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import CookieContainer from "./components/Pages/CookieContainer/CookieContainer";
import "./components/Pages/Pages.css";

class App extends Component {
	state = {
		sideDrawerOpen: false,
		showFooter: true,
		wasClicked: false,
	};

	drawerToggleClickHandler = () => {
		this.setState((prevState) => {
			return { sideDrawerOpen: !prevState.SideDrawerOpen };
		});
	};

	backdropClickHandler = () => {
		this.setState({ sideDrawerOpen: false });
	};

	render() {
		let backdrop;
		if (this.state.sideDrawerOpen) {
			backdrop = <Backdrop click={this.backdropClickHandler} />;
		}
		return (
			<Router>
				<ScrollToTop />
				<div style={{ height: "100%" }}>
					<Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
					<SideDrawer
						show={this.state.sideDrawerOpen}
						click={this.backdropClickHandler}
					/>
					{backdrop}
					<main style={{ paddingTop: "56px" }}>
						<Switch>
							<Route path="/" exact component={FrontPage} />

							<Route path="/sjukrathjalfun" exact component={Sjukrathjalfun} />
							<Route path="/sjukrathjalfun/gjaldskra" component={Gjaldskra} />

							<Route path="/starfsfolk" exact component={Starfsfolk} />
							<Route path="/starfsfolk/:id" component={Einstaklingur} />

							<Route path="/umokkur" exact component={UmOkkur} />

							<Route path="/umokkur/engjavegur" component={Engjavegur} />
							<Route path="/umokkur/slettuvegur" component={Slettuvegur} />
							<Route path="/umokkur/personuvernd" component={Personuvernd} />

							<Route path="/frettir" exact component={Frettir} />
							<Route path="/frettir/:id" component={News} />

							<Route path="/hafasamband" component={HafaSamband} />

							<Route component={Page404} />
						</Switch>
					</main>
					<Footer />
					<CookieContainer />
				</div>
			</Router>
		);
	}
}

export default App;
