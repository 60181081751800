import React from "react";
import Map from "../Map/Map";
import { Link } from "react-router-dom";

function Engjavegur() {
	return (
		<div className="Body">
			<div className="Title">
				<h1> Atlas Endurhæfing - Engjavegur 6, 104 Reykjavík </h1>
			</div>
			<div className="stadSections">
				<div className="stadRightSection">
					<p>
						Atlas Endurhæfing er staðsett að Engjavegi 6, í húsi
						Íþróttamiðstöðvar Íslands, þar sem Íþrótta- og Ólympíusamband
						Íslands, Íslensk Getspá og skrifstofur sérsambandanna eru
						staðsettar. Atlas endurhæfing er á fyrstu og annarri hæð í húsi
						Íþróttabandalags Reykjavíkur. Gengið er inn að aftanverðu
						(sunnanmegin) inn í stiga- og lyftuhús sem tengir Cafe Easy og hús
						ÍBR. Afgreiðslan er staðsett á fyrstu hæð en herbergi sjúkraþjálfara
						eru staðsett á fyrstu og annarri hæð. ​Ávallt skal koma í afgreiðslu
						áður en tími hefst til að staðfesta komu sína.
					</p>

					<h4>Athugið</h4>
					<p>
						Endurhæfing eftir liðskiptaaðgerðir hjá Klinik fara fram að
						Sléttuvegi 25 í húsi Sléttunar.
					</p>

					<h4> Opnunartími </h4>
					<p>
						Mánudaga - fimmtudaga 8:00 - 17:00.
						<br />
						Föstudaga 8:00 - 16:00. <br />
					</p>
					<h4> Heimilisfang </h4>
					<p>Engjavegur 6, 104 Reykjavík.</p>

					<h4> Sjá Atlas Sléttuvegi </h4>
					<div className="btn-div-stads">
						<Link to="/umokkur/slettuvegur" className="btn btn-border-pop">
							<p className="btnLabel">Sléttuvegur 25, 103 Reykjavík</p>
						</Link>
					</div>
				</div>

				<div className="stadLeftSection">
					<div className="map">
						<Map />
					</div>
				</div>
			</div>
			<div className="BottomSection">
				<img
					src={
						"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692104520/atlas2.0/locations/engjavegur_mkie9n.jpg"
					}
					alt="Engjavegur"
					className="stadImgSize"
				/>
			</div>
		</div>
	);
}

export default Engjavegur;
