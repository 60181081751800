import React from "react";

import Logo from "../../assets/image/AtlasLogoNoText.png";

function Page404() {
	return (
		<div className="Body">
			<div className="Page404Logo">
				<img src={Logo} alt="Atlas" className="umOkkurImg" />
			</div>
			<div className="Page404">
				<p className="Text404"> Síða fannst ekki</p>
			</div>
		</div>
	);
}

export default Page404;
