import React from "react";
import News from "../../Json/news.json";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Frettir() {
	return (
		<div className="Body">
			<div className="Title">
				<h1> Fréttir og greinar </h1>
			</div>
			<div className="FrettirGrid">
				{News.map((thisNews, index) => {
					return (
						<Link
							to={`/frettir/${thisNews.id}`}
							key={thisNews.id}
							className="FrettirEach"
						>
							{thisNews.image === "" ? (
								<LazyLoadImage
									src={
										"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692272978/atlas2.0/frettir/newsDefault_idazwm.jpg"
									}
									alt="Atlas"
									className="FrettirImgSize"
									effect="blur"
								/>
							) : (
								<LazyLoadImage
									src={thisNews.image}
									alt="Frétt"
									className="FrettirImgSize"
									effect="blur"
								/>
							)}
							<h2> {thisNews.title} </h2>
						</Link>
					);
				})}
			</div>
		</div>
	);
}

export default Frettir;
