import React from "react";
import emailjs from "emailjs-com";
import { Checkmark } from "react-checkmark";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function HafaSamband() {
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_jfu0opb",
				"template_752mj4b",
				e.target,
				"user_DSn71ERKhbLwj3njTVFsJ"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
		document.getElementById("checkMarkPos").style.display = "inline";
	}

	const emailTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	return (
		<div className="Body">
			<div className="Title">
				<h1> Hafa samband </h1>
			</div>
			<div className="SambandSections">
				<div className="SambandLeftSection">
					<form onSubmit={sendEmail}>
						<div className="form">
							<input type="text" name="name" maxLength="50" required />
							<label htmlFor="name" className="label-field">
								<span className="content-name"> Nafn </span>
							</label>
						</div>

						<div className="form">
							<input
								type="text"
								name="ssn"
								onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
										event.preventDefault();
									}
								}}
								maxLength="10"
								required
							/>
							<label htmlFor="ssn" className="label-field">
								<span className="content-name"> Kennitala </span>
							</label>
						</div>

						<div className="form">
							<input type="text" name="email" maxLength="100" required />
							<label htmlFor="email" className="label-field">
								<span className="content-name"> Netfang </span>
							</label>
						</div>

						<div className="form">
							<input
								type="text"
								name="phone"
								onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
										event.preventDefault();
									}
								}}
								maxLength="50"
								required
							/>
							<label htmlFor="phone" className="label-field">
								<span className="content-name"> Sími </span>
							</label>
						</div>

						<div className="form-large">
							<textarea
								className="skilabod"
								type="textarea"
								name="message"
								placeholder="Skilaboð"
								maxLength="800"
								required
							/>
							<label htmlFor="message" className="label-field">
								<span className="content-name"> </span>
							</label>
						</div>

						<div className="btn-div">
							<button type="submit" className="btn btn-border-pop">
								Senda skilaboð
							</button>
							<div id="checkMarkPos">
								<Checkmark size="30px" />
								<div className="messageRecived"> Móttekið </div>
							</div>
						</div>
					</form>
				</div>

				<div className="SambandRightSection">
					<div className="HafaSambandTextSection">
						<p>
							Afboða þarf bókaðan tíma hjá sjúkraþjálfara með góðum fyrirvara ef
							fólk hefur ekki tök á að mæta. Ef afboð berst innan 2 klukkustunda
							fyrir bókaðan tíma þarf að greiða 7.000 króna gjald. Vinsamlegast
							athugið að tímabókanir eru á ábyrgð viðskiptavina og
							Sjúkratryggingar Íslands taka ekki þátt í tímum sem ekki er mætt
							í.
						</p>
						<div>
							<h4>Netfang</h4>
							<p
								className="HafaSambandLinkur"
								onClick={() =>
									emailTab("mailto:afgreidsla@atlasendurhaefing.is")
								}
							>
								afgreidsla@atlasendurhaefing.is
							</p>
						</div>

						<div>
							<h4>Sími</h4>
							<p> 552-6600 </p>
						</div>
					</div>
					<div className="HafaSambandImageSection">
						<LazyLoadImage
							src={
								"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692110499/atlas2.0/images/mottaka_im0ft2.jpg"
							}
							alt="Móttaka"
							className="HafaSambandImage"
							effect="blur"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HafaSamband;
