import React from "react";
import "./CookieContainer.css";
import { Link } from "react-router-dom";

function CookieContainer() {
	const cookieContainer = document.querySelector(".cookieContainter");
	const cookieButton = document.querySelector(".cookieButton");

	if (cookieButton) {
		cookieButton.addEventListener("click", () => {
			cookieContainer.classList.remove("active");
		});
	}

	if (cookieButton) {
		setTimeout(() => {
			cookieContainer.classList.add("active");
		}, 2000);
	}

	function AcceptCookies() {
		const cookieContainer = document.querySelector(".cookieContainter");
		cookieContainer.classList.add("active");
		localStorage.setItem("cookieBannerDisplayed", true);
	}

	if (!localStorage.getItem("cookieBannerDisplayed")) {
		return (
			<div className="cookieContainter">
				<div className="cookieContainterText">
					<p>
						Þessi vefur notar vefkökur til að bæta upplifun notenda og greina
						umferð.
					</p>
				</div>
				<div className="cookieContainterActions">
					<div className="btn-div">
						<Link to="/umokkur/personuvernd" className="btn btn-border-pop">
							<p className="btnLabel">Sjá nánar</p>
						</Link>
					</div>
					<div className="btn-div">
						<button className="btn btn-border-pop" onClick={AcceptCookies}>
							<p className="btnLabel">Samþykkja</p>
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		return <div />;
	}
}

export default CookieContainer;
