import React, { useState } from "react";
import Sjukrathjalfarar from "../../Json/sjukrathjalfarar.json";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Starfsfolk() {
	const [filter, setFilter] = useState("");

	const handleFilter = (location) => {
		setFilter(location);
	};

	return (
		<div className="Body">
			<div className="TitleWButton">
				<h1>Starfsfólk</h1>
				<div className="titleButtons">
					<div className="btn-div-titles">
						<button
							onClick={() => handleFilter("")}
							className={`btn btn-border-pop ${
								filter === "" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Allir</p>
						</button>
						<button
							onClick={() => handleFilter("Engjavegur")}
							className={`btn btn-border-pop ${
								filter === "Engjavegur" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Engjavegur</p>
						</button>
						<button
							onClick={() => handleFilter("Sléttuvegur")}
							className={`btn btn-border-pop ${
								filter === "Sléttuvegur" ? "selected" : ""
							}`}
						>
							<p className="btnLabel">Sléttuvegur</p>
						</button>
					</div>
				</div>
			</div>
			<div className="StarfsfolkGrid">
				{Sjukrathjalfarar.map((sjukrathjalfari, index) => {
					if (filter && !sjukrathjalfari.stadsetning.includes(filter))
						return null;

					if (sjukrathjalfari.title !== "Sjúkraþjálfari") {
						return (
							<div key={sjukrathjalfari.id} className="StarfsfolkEach">
								<LazyLoadImage
									src={sjukrathjalfari.image}
									alt={sjukrathjalfari.name}
									className="StarfsfolkImgSize"
									effect="blur"
								/>
								<h2> {sjukrathjalfari.name} </h2>
								<p> {sjukrathjalfari.title} </p>
							</div>
						);
					}

					return (
						<Link
							to={`/starfsfolk/${sjukrathjalfari.id}`}
							key={sjukrathjalfari.id}
							className="StarfsfolkEach"
						>
							<LazyLoadImage
								src={sjukrathjalfari.image}
								alt={sjukrathjalfari.name}
								className="StarfsfolkImgSize"
								effect="blur"
							/>
							<h2> {sjukrathjalfari.name} </h2>
							<p> {sjukrathjalfari.title} </p>
						</Link>
					);
				})}
			</div>
		</div>
	);
}

export default Starfsfolk;
