import React from "react";

function Personuvernd() {
	return (
		<div className="Body">
			<div className="Title">
				<h1> Persónuverndarstefna </h1>
			</div>
			<div className="PersonuverndSections">
				<p>
					Félagið hefur sett sér persónuverndarstefnu sem er birt hér á vef
					fyrirtækisins. <br />
					Þeim einstaklingum sem óska eftir upplýsingum um vinnslu
					persónuupplýsinga um sig á grundvelli laga nr. 90/2018, gefst kostur á
					að leita til afgreiðslu félagsins, Engjavegi 6, 104 Reykjavík,
					símanúmer: 552-6600. Þar má nálgast eyðublöð með beiðni um veitingu
					upplýsinga um vinnsluna. Gerð er krafa um að viðkomandi einstaklingur
					sýni fullgild persónuskilríki með mynd um leið og upplýsingabeiðni er
					skilað, til að tryggja örugga auðkenningu. <br />
					Ekki er tekið við beiðnum um upplýsingar um vinnslu persónuupplýsinga
					í gegnum síma eða með tölvupósti, því slíkir samskiptamiðlar uppfylla
					ekki persónuverndarkröfur. Einstaklingar eru hvattir til þess að senda
					aldrei viðkvæmar persónuupplýsingar um sig með tölvupósti. <br />
					Pósthólf persónuverndarfulltrúa er: <br />
					personuvernd@atlasendurhaefing.is
				</p>

				<h2> 1. Inngangur </h2>
				<p>
					Persónuupplýsingar eru hvers kyns upplýsingar um persónugreindan eða
					persónugreinanlegan einstakling, þ.e. upplýsingar sem hægt er að rekja
					beint eða óbeint til einstaklings. Öll vinnsla félagsins á
					persónuupplýsingum fer fram í samræmi við lög um persónuvernd nr.
					90/2018 og almennu persónuverndarreglugerðina ESB/2016/679. Vinnsla
					með persónuupplýsingar sem flokkast sem heilbrigðisupplýsingar er um
					margt frábrugðin annars konar vinnslu persónuupplýsinga, m.a. hvað
					varðar hagsmuni hinna skráðu. Af þeim sökum er að finna mörg sérákvæði
					um slíka vinnslu í persónuverndarlögum sem taka mark af hinu sérstaka
					eðli vinnslunnar. Persónuverndarstefna félagsins tekur mið af þessari
					sérstöðu. Í henni er að finna lýsingu á því hvaða persónuupplýsingum
					félagið safnar og vinnur með, hvers vegna, hve lengi megi ætla að
					upplýsingarnar verði geymdar og með hvaða hætti sé gætt að öryggi
					þeirra.
				</p>

				<h2> 2. Tegundir persónuupplýsinga sem unnið er með. </h2>
				<p>
					Félagið notast við gögn sem veitt eru af viðskiptavinum í þjónustu við
					þá. Um er að ræða bæði persónuupplýsingar og viðkvæmar
					persónuupplýsingar þ.m.t. heilbrigðisupplýsingar sem skv. lögum skal
					vinna með sérstakri varúð. <br />Á félaginu hvílir einnig lagaskylda
					sem almennt hvílir á öllum íslenskum rekstraraðilum sem vinna ákveðin
					persónugreinanleg gögn, t.d. vegna starfsmannahalds og ákvæða
					bókhalds- og skattalaga. Þá geta persónuupplýsingar mögulega verið
					nýttar til að veita þátttakendum sérstakar upplýsingar sem þeir óska
					eftir ef þær teljast áreiðanlegar og hægt er að vinna þær án óhóflegs
					kostnaðar og fyrirhafnar.
				</p>

				<h2> 3. Tilgangur og heimildir fyrir vinnslu persónuupplýsinga. </h2>
				<p>
					Tilgangur félagsins og heimild til vinnslu persónuupplýsinga er sótt í
					5. og 6. gr. pvrg. Ef upplýsinga er aflað frá öðrum en viðkomandi
					einstaklingum sjálfum er það gert í samræmi við heimildir laga.
					Félagið notar persónuupplýsingar um viðskiptavini eingöngu í þeim
					tilgangi sem viðskiptavinir voru upplýstir um við söfnun þeirra. Ef
					félagið telur sig þurfa að nota upplýsingarnar í öðrum og óskyldum
					tilgangi þá verða viðskiptavinir upplýstir um það og á hvaða
					lagagrundvelli félagið telur slíka notkun heimila.
				</p>

				<h2> 3. Tilgangur og heimildir fyrir vinnslu persónuupplýsinga. </h2>
				<p>
					Tilgangur félagsins og heimild til vinnslu persónuupplýsinga er sótt í
					5. og 6. gr. pvrg. Ef upplýsinga er aflað frá öðrum en viðkomandi
					einstaklingum sjálfum er það gert í samræmi við heimildir laga.
					Félagið notar persónuupplýsingar um viðskiptavini eingöngu í þeim
					tilgangi sem viðskiptavinir voru upplýstir um við söfnun þeirra. Ef
					félagið telur sig þurfa að nota upplýsingarnar í öðrum og óskyldum
					tilgangi þá verða viðskiptavinir upplýstir um það og á hvaða
					lagagrundvelli félagið telur slíka notkun heimila.
				</p>

				<h2> 4. Hversu lengi geymum við gögnin? </h2>
				<p>
					{" "}
					Almennar upplýsingar um viðskiptavini eru varðveittar í 1 ár frá lokum
					viðskipta. Sé um að ræða upplýsingar sem falla undir bókhaldslög eru
					þær varðveittar í 7 ár frá lokum viðkomandi reikningsárs.{" "}
				</p>

				<h2> 5. Rafræn vöktun </h2>
				<p>
					Í öryggis- og eignavörsluskyni og á grundvelli lögmætra hagsmuna er
					notast við myndavélaeftirlit í húsnæði félagsins. Um heimildina má
					lesa í 14. gr. pvl. Vakin er athygli á notkun öryggismyndavéla með þar
					til gerðum merkjum. Upplýsingar sem safnast með rafrænni vöktun eru
					ekki varðveittar lengur en í 90 daga.
				</p>

				<h2> 6. Hvert er persónuupplýsingum miðlað? </h2>
				<p>
					Félagið miðlar ekki persónuupplýsingum eða persónugreinanlegum gögnum
					til annarra aðila. Hluti þeirra gagna sem unnið er með eru hýstar í
					skýjaþjónustu utan EU með samþykki hins skráða.
				</p>

				<h2> 7. Hvernig tryggjum við öryggi persónuupplýsinga? </h2>
				<p>
					Félagið leitast við að grípa til viðeigandi tæknilegra og skipulegra
					ráðstafana til að vernda persónuupplýsingar með sértöku tilliti til
					eðlis þeirra. Þessum ráðstöfunum er ætlað að vernda persónuupplýsingar
					gegn því að þær glatist eða breytist fyrir slysni og gegn óleyfilegum
					aðgangi, afritun, notkun eða miðlun þeirra. Dæmi um öryggisráðstafanir
					sem notast er við eru aðgangsstýringar í kerfum félagsins.
				</p>

				<h2> 8. Réttur einstaklinga. </h2>
				<p>
					Einstaklingur á rétt á að að fá upplýsingar um hvaða
					persónuupplýsingar eru unnar um hann innan þeirra marka og með þeim
					takmörkunum sem persónuverndarlög tilgreina, auk þess sem
					persónuverndarlög veita skráðum einstaklingum margháttuð réttindi sem
					varða meðferð á persónuupplýsingum um þá. Einnig eiga þátttakendur sem
					veitt hafa upplýst samþykki rétt á að draga til baka samþykki hvenær
					sem er og án sérstakra skýringa og verður þá persónuupplýsingum um þá
					eytt. Þá hefur einstaklingur rétt á að leggja fram kvörtun til
					Persónuverndar ef þeir telja að ekki hafi verið fylgt ákvæðum
					persónuverndarlaga um meðferð á persónuupplýsingum. Nánari upplýsingar
					má finna á vefsíðu Persónuverndar, personuvernd.is
				</p>

				<h2> 9. Hvernig uppfærum við eða breytum persónuverndarstefnunni? </h2>
				<p>
					Félagið getur breytt þessari persónuverndarstefnu og bætt við hana
					hvenær sem er og taka slíkar breytingar gildi án fyrirvara. Slíkar
					breytingar kunna t.d. að vera gerðar til að samræma
					persónuverndarstefnuna við gildandi lög og reglur er varða
					persónuvernd hverju sinni. Allar breytingar á stefnunni verða birtar á
					vefsíðunni: atlasendurhaefing.is
				</p>

				<p> Persónuverndarstefna þessi tók gildi 14. apríl 2020. </p>
			</div>
		</div>
	);
}

export default Personuvernd;
