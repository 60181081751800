import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Slettuvegur 25, 64.1226, -21.8968
const MapSlettuvegur = (props) => {
	const getMapOptions = (maps) => {
		return {
			disableDefaultUI: false,
			mapTypeControl: true,
			streetViewControl: false,
			styles: [
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [{ visibility: "on" }],
				},
			],
		};
	};

	const [center] = useState({ lat: 64.1226, lng: -21.8968 });
	const [zoom] = useState(15);
	return (
		<div style={{ height: "40vh", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: API_KEY }}
				defaultCenter={center}
				defaultZoom={zoom}
				yesIWantToUseGoogleMapApiInternals
				options={getMapOptions}
			>
				<Marker
					lat={64.1226}
					lng={-21.8968}
					name="Atlas Endurhæfing"
					color="red"
				/>
			</GoogleMapReact>
		</div>
	);
};

export default MapSlettuvegur;
