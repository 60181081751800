import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class StadsetningSlider extends React.Component {
	render() {
		const settings = {
			infinite: true,
			autoplay: true,
			arrows: false,
			autoplaySpeed: 3000,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 1014,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						infinite: true,
						dots: true,
					},
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true,
					},
				},
			],
		};
		const slideImages = [
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692181316/atlas2.0/images/atlas2_duxr4g.jpg",
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692181316/atlas2.0/images/atlas3_uzeu3t.jpg",
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692181316/atlas2.0/images/atlas4_wmobcu.jpg",
			"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692181316/atlas2.0/images/atlas1_yojieq.jpg",
		];
		return (
			<div className="allSliderImages">
				<Slider {...settings} className="StadSlider">
					<div>
						<LazyLoadImage
							src={slideImages[0]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>

					<div>
						<LazyLoadImage
							src={slideImages[1]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>

					<div>
						<LazyLoadImage
							src={slideImages[2]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>

					<div>
						<LazyLoadImage
							src={slideImages[3]}
							alt="Atlas"
							className="SliderImgSize"
							effect="blur"
						/>
					</div>
				</Slider>
			</div>
		);
	}
}

export default StadsetningSlider;
