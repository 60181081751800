import React from "react";
import Sjukrathjalfarar from "../../Json/sjukrathjalfarar.json";
import Message from "../../assets/Icons/message.svg";
import Map from "../../assets/Icons/map.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Einstaklingur({ match }) {
	const person = Sjukrathjalfarar.find((p) => p.id === match.params.id) || {};
	const {
		image = "",
		name = "",
		title = "",
		email = "",
		stadsetning = "",
		about = "",
		jobs = [],
		other = [],
	} = person;

	const emailTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	return (
		<div className="EinstaklingurBody">
			{title === "Sjúkraþjálfari" && (
				<div className="Einstaklingur">
					<div className="EinstaklingurLeft">
						<LazyLoadImage
							src={image}
							alt={name}
							className="EinstaklingurImgSize"
							effect="blur"
						/>
					</div>
					<div className="EinstaklingurRight">
						<div className="EinstaklingurRightTop">
							<div className="EinstaklingurTitle">
								<h1>{name}</h1>
								<h3>{title}</h3>
							</div>
							<div className="EinstaklingurInfo">
								<div className="EinstaklingurStadsetning">
									<img
										className="EinstaklingurLogoStadsetning"
										src={Map}
										alt="Staðsetning"
									/>
									<h3 className="EinstaklingurAddress">{stadsetning}</h3>
								</div>
								{email && email.trim() !== "" && (
									<div className="EinstaklingurEmail">
										<img
											className="EinstaklingurLogoEmail"
											src={Message}
											alt="Email"
										/>
										<h3
											className="EinstaklingurEmailAddress"
											onClick={() => emailTab(`mailto:${email}`)}
										>
											{email}
										</h3>
									</div>
								)}
							</div>
						</div>
						<div className="EinstaklingurAbout">
							<p> {about} </p>
						</div>
						<div className="EinstaklingurJobs">
							<h3> Starfsferill </h3>
							<ul>
								{jobs.map((job) => (
									<li key={job}> {job} </li>
								))}
							</ul>
						</div>
						{other[0] !== "" && (
							<div className="EinstaklingurOther">
								<h3> Annað </h3>
								<ul>
									{other.map((item) => (
										<li key={item}> {item} </li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}

export default Einstaklingur;
