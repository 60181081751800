import React, { Component } from "react";
import news from "../../Json/news.json";
import { Link } from "react-router-dom";
import "./NewsSlider.css"; // Update the name if needed
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class NewsDisplay extends Component {
	state = {
		numberOfNews: 4,
	};

	updateNumberOfNews = () => {
		if (window.matchMedia("(max-width: 750px)").matches) {
			this.setState({ numberOfNews: 3 });
		} else if (window.matchMedia("(max-width: 1150px)").matches) {
			this.setState({ numberOfNews: 2 });
		} else if (window.matchMedia("(max-width: 1660px)").matches) {
			this.setState({ numberOfNews: 3 });
		} else {
			this.setState({ numberOfNews: 4 });
		}
	};

	componentDidMount() {
		this.updateNumberOfNews();
		window.addEventListener("resize", this.updateNumberOfNews);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateNumberOfNews);
	}

	render() {
		const latestNews = news.slice(0, this.state.numberOfNews);

		return (
			<div className="allNews">
				{latestNews.map((thisNews) => (
					<div key={thisNews.id} className="frontNewsItem">
						<Link to={`/frettir/${thisNews.id}`} className="newsLink">
							<div className="frontNewsImageContainer">
								<LazyLoadImage
									src={
										thisNews.image ||
										"https://res.cloudinary.com/atlasendurhaefing/image/upload/v1692272978/atlas2.0/frettir/newsDefault_idazwm.jpg"
									}
									alt={thisNews.title}
									className="frontNewsImage"
									effect="blur"
								/>
							</div>
							<h3 className="frontNewsTitle">{thisNews.title}</h3>
							<p className="frontNewsDate">{thisNews.date}</p>
							<div style={{ whiteSpace: "pre-line" }}>
								<p className="frontNewsDescription">{thisNews.description}</p>
							</div>
						</Link>
					</div>
				))}
			</div>
		);
	}
}

export default NewsDisplay;
